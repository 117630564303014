/* eslint-disable react/jsx-indent */
import React, { useState, useEffect, useRef } from 'react';
import { graphql, Link } from 'gatsby';
import Layout from '../components/layout/Layout';
import ImageFromField from '../components/global/ImageFromField';
import useAuth from '../util/hooks/useAuth';
import SEO from '../components/SEO';

const Article = ({ classes, story }) => {
  const excerpt = story.body.processed.split(' ').splice(0, 42).join(' ');

  return (
    <article className={`story ${classes}`}>
      {story.relationships.field_story_image?.uri ? (
        <Link className="img-wrapper" to={story.path.alias}>
          <ImageFromField img={story.relationships.field_story_image} />
        </Link>
      ) : null}
      <div className="the-content t-left">
        <h1 className="t-medium t-heading news-title">
          <Link to={story.path.alias}>{story.title}</Link>
        </h1>
        <div dangerouslySetInnerHTML={{ __html: excerpt }}></div>
        <Link className="button" to={story.path.alias}>
          Read More
        </Link>
      </div>
    </article>
  );
};

const NewsPage = ({ data }) => {
  const [device, setDevice] = useState('mobile');
  const [page, setPage] = useState(1);
  useAuth();

  const { edges: stories } = data.allStories;
  const { edges: highlights } = data.allHighlights;

  let storiesToShow = [...new Set(stories)];
  const subMenu = { title: 'about', extra: true };
  const pageLength = 6;

  const handleScroll = () => {
    if (isInViewport(infiniteScroller)) {
      setPage(page + 1);
    }
  };

  const infiniteScroller = useRef(null);
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll, page, setPage]);

  const isInViewport = (el, offset = 0) => {
    if (!el.current) return false;
    const top = el.current.getBoundingClientRect().top;
    return top + offset >= 0 && top - offset <= window.innerHeight;
  };

  if (storiesToShow.length > pageLength * page) {
    // paginate
    storiesToShow = storiesToShow.slice(0, pageLength * page);
  }

  let featuredCount = 0;

  return (
    <Layout
      device={device}
      setDevice={setDevice}
      logo={data.logo}
      footerMenu={data.footer_menu}
      menu={data.allMenuMenuLink}
      subMenu={subMenu}
      locations={data.locations.edges}
    >
      <SEO title="News Articles" />
      <main className="news-story-home">
        <section className="grid-section grid-content-container">
          <div className="t-heading-container">
            <h1 className="t-uppercase">News Articles</h1>
          </div>
          <h2 className="t-uppercase">Highlights</h2>
          <div className="spacer"></div>
          <div className="columns highlights">
            {highlights.map(({ node: story }, i) => {
              featuredCount++;

              if (featuredCount > 3) return;

              return (
                <article className="story column is-4" key={`article_${i}`}>
                  <Link to={story.path.alias}>
                    {story.relationships.field_story_image?.uri ? (
                      <div className="img-wrapper">
                        <ImageFromField
                          img={story.relationships.field_story_image}
                          imageSize="gatsby_news_thumbnail"
                        />
                      </div>
                    ) : null}
                    <h1 className="t-medium t-center t-heading news-title">
                      {story.title}
                    </h1>
                  </Link>
                </article>
              );
            })}
          </div>
          <h2 className="t-uppercase">Recent News</h2>
          <div className="spacer"></div>
          <div className="columns">
            <div className="">
              {storiesToShow.map(({ node: story }, i) => (
                <Article classes="column is-12" story={story} />
              ))}
            </div>
          </div>
        </section>
        <div
          className="infinite-scroll-detection"
          ref={infiniteScroller}
          style={{ padding: '40px' }}
        >
          &nbsp;
        </div>
      </main>
    </Layout>
  );
};

export default NewsPage;

export const NewsPageQuery = graphql`
  query NewsPageQuery {
    allMenuMenuLink(
      sort: { fields: [weight], order: ASC }
      filter: { menu_name: { eq: "gatsby-menu" } }
    ) {
      edges {
        node {
          enabled
          title
          expanded
          external
          langcode
          weight
          link {
            uri
          }
          drupal_parent_menu_item
          bundle
          drupal_id
          menu_name
        }
      }
    }
    footer_menu: allMenuMenuLink(
      sort: { fields: [weight], order: ASC }
      filter: { menu_name: { eq: "footer" } }
    ) {
      edges {
        node {
          enabled
          title
          expanded
          external
          langcode
          weight
          link {
            uri
          }
          bundle
          drupal_id
          menu_name
        }
      }
    }
    locations: allNodeLocation {
      edges {
        node {
          field_address {
            address_line1
            address_line2
            country_code
            locality
            postal_code
          }
          field_address_description
          field_address_short
          field_location_email
          field_location_fax_number
          field_location_phone_number
          title
          field_office_hours_description
        }
      }
    }
    allStories: allNodeStory(
      filter: { status: { eq: true } }
      sort: { fields: created, order: DESC }
    ) {
      edges {
        node {
          title
          path {
            alias
          }
          body {
            processed
          }
          relationships {
            field_story_image {
              image_style_uri {
                gatsby_news_thumbnail
              }
              uri {
                url
              }
            }
          }
        }
      }
    }
    allHighlights: allNodeStory(
      sort: { fields: created, order: DESC }
      filter: { field_significant_story: { in: 1 }, status: { eq: true } }
      limit: 3
    ) {
      edges {
        node {
          title
          path {
            alias
          }
          body {
            processed
          }
          relationships {
            field_story_image {
              image_style_uri {
                gatsby_news_thumbnail
              }
              uri {
                url
              }
            }
          }
        }
      }
    }
  }
`;
